.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
}

.ascii-art {
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.5rem;
  text-align: center;
}
